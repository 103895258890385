var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Popover Events"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeEvent) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Popover have four events : "), _c('code', [_vm._v("Show, Shown, Hide, Hidden")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-show-event",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Show Event popover ")]), _c('b-popover', {
    ref: "popover-show",
    attrs: {
      "target": "popover-button-show-event",
      "triggers": "click"
    },
    on: {
      "show": _vm.onShow
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Show Event Popover ")];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Bonbon chocolate cake. Pudding halvah pie apple pie topping marzipan pastry marzipan cupcake.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-shown-event",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Shown Event popover ")]), _c('b-popover', {
    ref: "popover-shown",
    attrs: {
      "target": "popover-button-shown-event",
      "triggers": "click"
    },
    on: {
      "shown": _vm.onShown
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Shown Event Popover ")];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Bonbon chocolate cake. Pudding halvah pie apple pie topping marzipan pastry marzipan cupcake.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-hide-event",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Hide Event popover ")]), _c('b-popover', {
    ref: "popover-hide",
    attrs: {
      "target": "popover-button-hide-event",
      "triggers": "click"
    },
    on: {
      "hide": _vm.onHide
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Hide Event Popover ")];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Bonbon chocolate cake. Pudding halvah pie apple pie topping marzipan pastry marzipan cupcake.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-hidden-event",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Hidden Event popover ")]), _c('b-popover', {
    ref: "popover-hidden",
    attrs: {
      "target": "popover-button-hidden-event",
      "triggers": "click"
    },
    on: {
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Hidden Event Popover ")];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Bonbon chocolate cake. Pudding halvah pie apple pie topping marzipan pastry marzipan cupcake.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }