var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Popover Variant"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("BootstrapVue's popovers support contextual color variants via our custom CSS, via the")]), _c('code', [_vm._v("variant")]), _c('span', [_vm._v(" prop:")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    attrs: {
      "id": "popover-button-default",
      "variant": "gradient-primary"
    }
  }, [_vm._v(" Default ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-default",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Default!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-variant",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Primary ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-variant",
      "variant": "primary",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Primary!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-secondary",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Secondary ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-secondary",
      "variant": "secondary",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Secondary!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-success",
      "variant": "outline-success"
    }
  }, [_vm._v(" Success ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-success",
      "variant": "success",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Success!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-danger",
      "variant": "outline-danger"
    }
  }, [_vm._v(" Danger ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-danger",
      "variant": "danger",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Danger!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-warning",
      "variant": "outline-warning"
    }
  }, [_vm._v(" Warning ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-warning",
      "variant": "warning",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Warning!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-info",
      "variant": "outline-info"
    }
  }, [_vm._v(" Info ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-info",
      "variant": "info",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Info!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(30, 30, 30, 0.15)',
      expression: "'rgba(30, 30, 30, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "popover-button-dark",
      "variant": "outline-dark"
    }
  }, [_vm._v(" Dark ")]), _c('b-popover', {
    attrs: {
      "target": "popover-button-dark",
      "variant": "dark",
      "triggers": "focus",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Info!")])];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }